import React from "react"

export const lowercase = {
  name: (
    <span>
      pierreplume<sup>®</sup>
    </span>
  ),
}

export const uppercase = {
  name: (
    <span>
      Pierreplume<sup>®</sup>
    </span>
  ),
}
