import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { SIZES } from "src/layout/constants"
import { Top2, Section, Row, Column, Text, Box } from "src/components/styled"
import { uppercase } from "src/components/Name"

const Images = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  margin: -20px;
  .gatsby-image-wrapper {
    flex: 0 1 calc(50% - 40px);
    margin: 20px;
  }
  @media (max-width: 799px) {
    margin: ${SIZES.mobileMargin / 2}px ${SIZES.mobileMargin / 2}px 0;
    .gatsby-image-wrapper {
      flex: 0 1 calc(50% - ${SIZES.mobileMargin}px);
      margin: ${SIZES.mobileMargin / 2}px;
    }
  }
`

interface DataType {
  collaboration: { childImageSharp: Media }
  thumb1: { childImageSharp: Media }
  thumb2: { childImageSharp: Media }
  thumb3: { childImageSharp: Media }
  thumb4: { childImageSharp: Media }
}

const CollaborationPage: GatsbyPage<DataType> = ({ data }) => {
  return (
    <Layout title="recycledCollaboration" subMenu="recycled">
      <Top2>
        <FormattedMessage id="recycled.collaboration" tagName="h1" />
        <FormattedMessage id="recycled.collaborationText" tagName="p" values={uppercase} />
      </Top2>
      <Section>
        <Image fluid={data.collaboration.childImageSharp.fluid} />
      </Section>
      <Section>
        <Row>
          <Images>
            <Image fluid={data.thumb3.childImageSharp.fluid} />
            <Image fluid={data.thumb1.childImageSharp.fluid} />
            <Image fluid={data.thumb4.childImageSharp.fluid} />
            <Image fluid={data.thumb2.childImageSharp.fluid} />
          </Images>
          <Column>
            <FormattedMessage id="recycled.resources" tagName="h1" />
            <FormattedMessage id="recycled.resourcesText" tagName={Text} />
          </Column>
        </Row>
      </Section>
      <Box>
        <FormattedMessage id="recycled.contactTitle" tagName="h1" />
        <Text>
          <FormattedMessage id="recycled.contactText" />
          <br />
          <br />
          <Link to="/contact/">
            <FormattedMessage id="recycled.contactLink" />
          </Link>
        </Text>
      </Box>
    </Layout>
  )
}

export default CollaborationPage

export const query = graphql`
  query {
    collaboration: file(relativePath: { eq: "recycled/collaboration.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumb1: file(relativePath: { eq: "recycled/thumb1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumb2: file(relativePath: { eq: "recycled/thumb2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumb3: file(relativePath: { eq: "recycled/thumb3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thumb4: file(relativePath: { eq: "recycled/thumb4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
